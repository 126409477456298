import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
        <div className="container">
          <a className="navbar-brand" href="index.html">
            <img src="assets/11Goods.png" alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto">
              
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Categories <i className="bi bi-chevron-down"></i>
                </a>
                <div className="dropdown-menu">
                  <ul>
                    <li>
                      <a href="/">Option 1</a>
                    </li>
                    <li>
                      <a href="/">Option 2</a>
                    </li>
                    <li>
                      <a href="/">Option 3</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/">
                  Tips & Advices
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Daily Ideas
                </a>
              </li>
            </ul>
            <div className="d-flex nav-buttons">
              <Link to="/signin">
              <button className="first-btn me-3" type="submit">
                Login/ Register
              </button>
              </Link>
              <button className="btn-2">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                <img src="assets/twemoji_flag-united-states.png" alt="" /> English <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className="dropdown-menu">
                    <ul>
                      <li>
                        <a href="/">Option 1</a>
                      </li>
                      <li>
                        <a href="/">Option 2</a>
                      </li>
                      <li>
                        <a href="/">Option 3</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
