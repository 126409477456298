import React from "react";
import Sidebar from "./Sidebar";
import Piechart from "./Piechart";
// import { Pie } from "react-chartjs-2";
// import ChartDataLabels from 'chartjs-plugin-datalabels';

const Dashboard = () => {
  const data1 = [
    { value: 45, color: "#775DA6", label: "45%" },
    { value: 10, color: "#70B6C1", label: "10%" },
    { value: 45, color: "#FFB1B7", label: "45%" },
  ];
  const data2 = [
    { value: 45, color: "#775DA6", label: "45%" },
    { value: 10, color: "#70B6C1", label: "10%" },
    { value: 45, color: "#FFB1B7", label: "45%" },
  ];

  return (
    <>
      <Sidebar />

      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Dashboard</h1>
          </div>

          <div className="row dash-row">
            <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Product Listed</h3>
                  <span>
                    <i className="bi bi-arrow-up"></i> 3%
                  </span>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>2700</h2>
                    <h5>1,000 last year</h5>
                  </div>
                  <div>
                    <img src="assets/Vector 1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Total Order</h3>
                  <span>
                    <i className="bi bi-arrow-up"></i> 3%
                  </span>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>2005</h2>
                    <h5>17,012 last year</h5>
                  </div>
                  <div>
                    <img src="assets/Vector 1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Total Earning</h3>
                  <span>
                    <i className="bi bi-arrow-up"></i> 3%
                  </span>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>$ 192,005</h2>
                    <h5>17,012 last year</h5>
                  </div>
                  <div>
                    <img src="assets/Vector 1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row dash-row2">
            <div className="col-lg-4">
              <div className="dash-box">
                <h2>Sales Data</h2>
                <h5>March 2020</h5>
                <div className="pie-label">
                  <div className="d-flex">
                    <span className="span1"> </span>
                    <p> Profit Margin</p>
                  </div>
                  <div className="d-flex">
                    <span className="span2"> </span>
                    <p> Selling Price</p>
                  </div>
                  <div className="d-flex">
                    <span className="span3"> </span>
                    <p> Purchase Cost</p>
                  </div>
                </div>
                <div className="pie d-flex justify-content-center">
                  <Piechart data={data1} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dash-box">
                <h2>Distributed Data</h2>
                <h5>March 2020</h5>
                <div className="pie-label">
                  <div className="d-flex">
                    <span className="span1"> </span>
                    <p> Amazon Fees</p>
                  </div>
                  <div className="d-flex">
                    <span className="span2"> </span>
                    <p> Supplier Fees</p>
                  </div>
                  <div className="d-flex">
                    <span className="span3"> </span>
                    <p> Taxes</p>
                  </div>
                </div>
                <div className="pie d-flex justify-content-center">
                  <Piechart data={data2} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dash-box">
                <div className="order1 d-flex justify-content-between align-items-center">
                <div>
                  <h3>Recent Orders</h3>
                  <h6>Total: 80 Orders</h6>
                </div>
                <div>
                  <span>
                    All Orders <img src="assets/up-down.png" alt="" />
                  </span>
                </div>
                </div>
                <div className="order2">
                    <div className="d-flex">
                        <div className="order-img">
                            <img src="assets/keypad.png" alt="" />
                        </div>
                        <div>
                            <h5>Asus ROG Strix Scope NX TKL Moonlight</h5>
                            <p>15 Items</p>
                        </div>
                    </div>
                    <div>
                        <p>1024 sales</p>
                    </div>
                </div>
                <div className="order2">
                    <div className="d-flex">
                        <div className="order-img">
                            <img src="assets/keypad.png" alt="" />
                        </div>
                        <div>
                            <h5>Asus ROG Strix Scope NX TKL Moonlight</h5>
                            <p>15 Items</p>
                        </div>
                    </div>
                    <div>
                        <p>1024 sales</p>
                    </div>
                </div>
                <div className="order2">
                    <div className="d-flex">
                        <div className="order-img">
                            <img src="assets/keypad.png" alt="" />
                        </div>
                        <div>
                            <h5>Asus ROG Strix Scope NX TKL Moonlight</h5>
                            <p>15 Items</p>
                        </div>
                    </div>
                    <div>
                        <p>1024 sales</p>
                    </div>
                </div>
                <div className="order2">
                    <div className="d-flex">
                        <div className="order-img">
                            <img src="assets/keypad.png" alt="" />
                        </div>
                        <div>
                            <h5>Asus ROG Strix Scope NX TKL Moonlight</h5>
                            <p>15 Items</p>
                        </div>
                    </div>
                    <div>
                        <p>1024 sales</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
