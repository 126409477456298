import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container-fluid footer">
        <div className="container footer-c">
          <div className="row footer-row1">
            <div className="col-lg-8 footer-heading">
              <h1>Do you need help ?</h1>
              <p>
                We will provide detailed information about our services, types
                of work, and top projects. We will calculate the cost and
                prepare a commercial proposal.
              </p>
            </div>
            <div className="col-lg-4 footer-get">
                <h4>Get Consultation <i className="bi bi-arrow-right"></i></h4>
            </div>
          </div>
          <div className="row footer-row2">
            <div className="row col-lg-7 footer-lists">
                <div className="col-lg-3 footer-list">
                    <h2>For Business</h2>
                    <div>
                        <li>Ventilation</li>
                        <li>Design</li>
                        <li>Air Conditioning</li>
                        <li>Installation</li>
                        
                    </div>
                </div>
                <div className="col-lg-3 footer-list">
                    <h2>For Home</h2>
                    <div>
                        <li>Ventilation</li>
                        <li>Air Conditioning</li>
                        <li>Installation</li>
                    </div>
                </div>
                <div className="col-lg-3 footer-list">
                    <h2>For Flat</h2>
                    <div>
                        <li>Ventilation</li>
                        <li>Design</li>
                        <li>Air Conditioning</li>
                        <li>Installation</li>
                    </div>
                </div>
                <div className="col-lg-3 footer-list">
                    <h2>Info</h2>
                    <div>
                        <li>About Us</li>
                        <li>Work</li>
                        <li>Contact Us</li>
                    </div>
                </div>
            </div>
            <div className="col-lg-2 footer-contact">
                <img src="assets/11Goods.png" alt="" />
                <li>+1 981 981-23-19</li>
                <li>hello@11goods.com</li>
                
            </div>
          </div>

          <div className="footer-row3">
            <p>© 2024 — Copyright</p>
            <div>
            <i className="bi bi-arrow-up"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
