import React, { useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import $ from "jquery";

const Product = () => {
  const dataTableRef = useRef();

  useEffect(() => {
    $(dataTableRef.current).DataTable();
  }, []);

  return (
    <>
      <Sidebar />

      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Product Management</h1>
          </div>

          <div className="container-fluid">
            <div class="input-group search-group">
              <div class="input-group-prepend search-prepend">
                <span class="input-group-text search-text" id="basic-addon1">
                  <i className="bi bi-search"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control search-control"
                placeholder="Search by name"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column">
                <div className="container mt-4">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        <th>
                          <input type="checkbox" />
                        </th>
                        <th>Product Id</th>
                        <th>Product Name</th>
                        <th>Product Image</th>
                        <th>Product Price</th>

                        <th>Quantity</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>#09089</td>
                        <td>GoPro HERO6 4K Acti...</td>
                        <td>
                          <img
                            src="assets/image22.png"
                            width="50"
                            height="50"
                            alt=""
                          />
                        </td>
                        <td>$99.70</td>
                        <td>20</td>
                        <td>
                          <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                          <i className="fa-solid fa-trash"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>#09089</td>
                        <td>GoPro HERO6 4K Acti...</td>
                        <td>
                          <img
                            src="assets/image22.png"
                            width="50"
                            height="50"
                            alt=""
                          />
                        </td>
                        <td>$99.70</td>
                        <td>20</td>
                        <td>
                          <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                          <i className="fa-solid fa-trash"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>#09089</td>
                        <td>GoPro HERO6 4K Acti...</td>
                        <td>
                          <img
                            src="assets/image22.png"
                            width="50"
                            height="50"
                            alt=""
                          />
                        </td>
                        <td>$99.70</td>
                        <td>20</td>
                        <td>
                          <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                          <i className="fa-solid fa-trash"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>#09089</td>
                        <td>GoPro HERO6 4K Acti...</td>
                        <td>
                          <img
                            src="assets/image22.png"
                            width="50"
                            height="50"
                            alt=""
                          />
                        </td>
                        <td>$99.70</td>
                        <td>20</td>
                        <td>
                          <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                          <i className="fa-solid fa-trash"></i>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>#09089</td>
                        <td>GoPro HERO6 4K Acti...</td>
                        <td>
                          <img
                            src="assets/image22.png"
                            width="50"
                            height="50"
                            alt=""
                          />
                        </td>
                        <td>$99.70</td>
                        <td>20</td>
                        <td>
                          <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                          <i className="fa-solid fa-trash"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
