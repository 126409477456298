import React, { useState } from "react";
import Sidebar from "./Sidebar";

const Settings = () => {

    const [showPassword, setShowPassword] = useState(null);

    const togglePasswordVisibility = (index) => {
      setShowPassword(showPassword === index ? null : index);
    };
  return (
    <>
      <Sidebar />

      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Accounts & Settings</h1>
          </div>
          <div className="settings">
            <h1>Profile Picture</h1>
            <div>
              <img src="assets/p2.png" alt="" />
              <span>
                <i className="bi bi-pencil"></i> Edit
              </span>
            </div>

            <form action="">
              <div className="row settings-row">
                <div className="col-lg-6">
                  <div className="settings-item">
                    <label htmlFor="name">Name</label>
                    <input type="text" placeholder="Name" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="settings-item">
                    <label htmlFor="name">Email id</label>
                    <input type="text" placeholder="Email" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="settings-item">
                    <label htmlFor="name">Mobile Number</label>
                    <input type="text" placeholder="000 000 0000" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="settings-item">
                    <label htmlFor="name">Membership</label>
                    <input type="text" placeholder="Standard" />
                  </div>
                </div>
                <div>
                  <button className="btn btn-primary mt-2">
                    Update Profile
                  </button>
                </div>
              </div>
            </form>
            <form action="">
              <div className="row pass-row">
                <h1>Change Password</h1>
              
              <div className="col-lg-6">
                <div className="pass-item">
                  <label htmlFor="password">Old Password</label>
                  <div class="input-group pass-group">
                    
                    <input
                      type={showPassword === 1 ? "text" : "password"}
                      class="form-control pass-control"
                      placeholder="Old Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                    <div class="input-group-prepend pass-prepend">
                      <span
                        class="input-group-text pass-text"
                        id="basic-addon1"
                        onClick={() => togglePasswordVisibility(1)}
                      >
                        <i className="bi bi-eye"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
                <div className="pass-item">
                  <label htmlFor="password">New Password</label>
                  <div class="input-group pass-group">
                    
                    <input
                      type={showPassword === 2 ? "text" : "password"}
                      class="form-control pass-control"
                      placeholder="New Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                    <div class="input-group-prepend pass-prepend">
                      <span
                        class="input-group-text pass-text"
                        id="basic-addon1"
                        onClick={() => togglePasswordVisibility(2)}
                      >
                        <i className="bi bi-eye"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="pass-item">
                  <label htmlFor="password">Confirm Password</label>
                  <div class="input-group pass-group">
                    
                    <input
                      type={showPassword === 3 ? "text" : "password"}
                      class="form-control pass-control"
                      placeholder="Confirm Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                    <div class="input-group-prepend pass-prepend">
                      <span
                        class="input-group-text pass-text"
                        id="basic-addon1"
                        onClick={() => togglePasswordVisibility(3)}
                      >
                        <i className="bi bi-eye"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button className="btn btn-primary mt-2">Change Password</button>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
