import React, { useEffect, useRef } from 'react';

const Piechart = ({ data }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let startAngle = 0;

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Calculate total value of all data points
    const total = data.reduce((acc, curr) => acc + curr.value, 0);

    // Draw pie chart
    data.forEach((item) => {
      const sliceAngle = (2 * Math.PI * item.value) / total;
      ctx.fillStyle = item.color;
      ctx.beginPath();
      ctx.moveTo(canvas.width / 2, canvas.height / 2);
      ctx.arc(canvas.width / 2, canvas.height / 2, canvas.height / 2, startAngle, startAngle + sliceAngle);
      ctx.closePath();
      ctx.fill();

      ctx.strokeStyle = '#fff'; // Border color
      ctx.lineWidth = 2; // Border width
      ctx.beginPath();
      ctx.moveTo(canvas.width / 2, canvas.height / 2);
      ctx.arc(canvas.width / 2, canvas.height / 2, canvas.height / 2, startAngle, startAngle + sliceAngle);
      ctx.closePath();
      ctx.stroke();
      startAngle += sliceAngle;

      const midAngle = startAngle - sliceAngle / 2;
      const textX = Math.cos(midAngle) * (canvas.width / 2) * 0.8 + canvas.width / 2;
      const textY = Math.sin(midAngle) * (canvas.height / 2) * 0.8 + canvas.height / 2;
      ctx.fillStyle = '#fff';
      ctx.textAlign = 'center';
      ctx.font = '14px Inter bold';
      ctx.fillText(item.label, textX, textY);
    });
  }, [data]);

  return <canvas ref={canvasRef} width={200} height={200} />;
};

export default Piechart;
