import React, { useState } from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [isActive, setIsActive] = useState(false);

  //   const [activeSubmenu, setActiveSubmenu] = useState(null);

  //   const toggleSubmenu = (index) => {
  //     setActiveSubmenu(activeSubmenu === index ? null : index);
  //   };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className={`sidebar ${isActive ? "close" : ""}`}>
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          <img src="assets/11Goods.png" alt="" />
        </Link>

        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link to="/dashboard" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M8.557 2.75H4.682A1.932 1.932 0 0 0 2.75 4.682v3.875a1.942 1.942 0 0 0 1.932 1.942h3.875a1.942 1.942 0 0 0 1.942-1.942V4.682A1.942 1.942 0 0 0 8.557 2.75m10.761 0h-3.875a1.942 1.942 0 0 0-1.942 1.932v3.875a1.943 1.943 0 0 0 1.942 1.942h3.875a1.942 1.942 0 0 0 1.932-1.942V4.682a1.932 1.932 0 0 0-1.932-1.932M8.557 13.5H4.682a1.943 1.943 0 0 0-1.932 1.943v3.875a1.932 1.932 0 0 0 1.932 1.932h3.875a1.942 1.942 0 0 0 1.942-1.932v-3.875a1.942 1.942 0 0 0-1.942-1.942m8.818-.001a3.875 3.875 0 1 0 0 7.75a3.875 3.875 0 0 0 0-7.75"
                  />
                </svg>
                <span className="name">Dashboard</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/dashboard" className="link submenu-title">
                Dashboard
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/product" className="link">
                <svg
                  class="icon-sidebar"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 2048 2048"
                >
                  <path
                    fill="currentColor"
                    d="m1344 2l704 352v785l-128-64V497l-512 256v258l-128 64V753L768 497v227l-128-64V354zm0 640l177-89l-463-265l-211 106zm315-157l182-91l-497-249l-149 75zm-507 654l-128 64v-1l-384 192v455l384-193v144l-448 224L0 1735v-676l576-288l576 288zm-640 710v-455l-384-192v454zm64-566l369-184l-369-185l-369 185zm576-1l448-224l448 224v527l-448 224l-448-224zm384 576v-305l-256-128v305zm384-128v-305l-256 128v305zm-320-288l241-121l-241-120l-241 120z"
                  />
                </svg>

                <span className="name">Product Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Product Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/withdrawal" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="currentColor"
                    d="M232 198.65V240a8 8 0 0 1-16 0v-41.35A74.84 74.84 0 0 0 192 144v58.35a8 8 0 0 1-14.69 4.38l-10.68-16.31c-.08-.12-.16-.25-.23-.38a12 12 0 0 0-20.89 11.83l22.13 33.79a8 8 0 0 1-13.39 8.76l-22.26-34l-.24-.38A28 28 0 0 1 176 176.4V64h-16a8 8 0 0 1 0-16h16a16 16 0 0 1 16 16v59.62a90.89 90.89 0 0 1 40 75.03M88 56a8 8 0 0 0-8-8H64a16 16 0 0 0-16 16v136a8 8 0 0 0 16 0V64h16a8 8 0 0 0 8-8m69.66 42.34a8 8 0 0 0-11.32 0L128 116.69V16a8 8 0 0 0-16 0v100.69L93.66 98.34a8 8 0 0 0-11.32 11.32l32 32a8 8 0 0 0 11.32 0l32-32a8 8 0 0 0 0-11.32"
                  />
                </svg>
                <span className="name">Withdrawals</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Withdrawals</Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link to="/membership" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M4 12.577v1.808q0 .23.192.423t.423.192h14.77q.23 0 .423-.192t.192-.424v-1.807zM4.615 3h14.77q.67 0 1.143.472q.472.472.472 1.144v9.769q0 .67-.472 1.143q-.472.472-1.143.472h-4.462v4.27L12 18.807l-2.923 1.461V16H4.616q-.672 0-1.144-.472T3 14.385v-9.77q0-.67.472-1.143Q3.944 3 4.616 3M4 10.423h16V4.616q0-.231-.192-.424T19.385 4H4.615q-.23 0-.423.192T4 4.615zm0 3.962V4h.616q-.231 0-.424.192T4 4.615zq0 .23.192.423t.423.192H4z"
                  />
                </svg>
                <span className="name">Membership</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Membership</Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link to="/settings" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m9.25 22l-.4-3.2q-.325-.125-.612-.3t-.563-.375L4.7 19.375l-2.75-4.75l2.575-1.95Q4.5 12.5 4.5 12.338v-.675q0-.163.025-.338L1.95 9.375l2.75-4.75l2.975 1.25q.275-.2.575-.375t.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3t.562.375l2.975-1.25l2.75 4.75l-2.575 1.95q.025.175.025.338v.674q0 .163-.05.338l2.575 1.95l-2.75 4.75l-2.95-1.25q-.275.2-.575.375t-.6.3l-.4 3.2zm2.8-6.5q1.45 0 2.475-1.025T15.55 12t-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12t1.013 2.475T12.05 15.5"
                  />
                </svg>
                <span className="name">Account & Settings</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Account & Settings</Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link to="/" className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 12l-4-4m4 4l-4 4m4-4H9m5 9a9 9 0 1 1 0-18"
                  />
                </svg>
                <span className="name">Logout</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Logout</Link>
            </div>
          </li>
          <li className="side-profile d-flex">
            <div className="profile-img">
                <img src="assets/profile.png" alt="" />
            </div>
            <div className="side-name">
                <h3>Username</h3>
                <h5>abc@xyz.com</h5>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
