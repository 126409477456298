import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Signup = () => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Navbar />

      <div className="container signin">
        <div className="row sign-row">
          <div className="col-lg-6">
            <div className="sign-left">
              <div className="sign-img">
                <img src="assets/Logo.png" alt="" />
              </div>
              <h3>Welcome !!!</h3>
              <h1>Sign Up</h1>
              <form action="/" className="sign-form">
                <label htmlFor="name">Full Name</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your name"
                    aria-label="email"
                    aria-describedby="basic-addon2"
                  />
                </div>
                <label htmlFor="name">Email</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your email"
                    aria-label="email"
                    aria-describedby="basic-addon2"
                  />
                </div>
                <label htmlFor="name">Phone no.</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter your number"
                    aria-label="email"
                    aria-describedby="basic-addon2"
                  />
                </div>
                <label htmlFor="name">Membership Type</label>
                <select class="select-group">
                  <option value="1"> Enterprise</option>
                  <option value="1"> Enterprise</option>
                  <option value="1"> Enterprise</option>
                  <option value="1"> Enterprise</option>
                </select>
                <label htmlFor="password">Password</label>
                <div class="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    class="form-control"
                    placeholder="Password"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" onClick={togglePasswordVisibility}>
                      <i className="bi bi-eye"></i>
                    </span>
                  </div>
                </div>
                <div className="submit col-lg-10">
                  <p>
                    <input type="checkbox" className="me-2" />
                    By submitting this form i agree to 
                    <span>
                      terms & Condition, Privacy Policy, & Cookie Policy
                    </span>
                  </p>
                </div>
                <div className="sign-button">
                  <Link to="/signin">
                    <button>
                      SIGN UP <i className="bi bi-arrow-right"></i>
                    </button>
                  </Link>
                </div>
                <div className="sign-acc">
                  <p>
                    Already have an account ?{" "}
                    <Link to="/signin" className="text-decoration-none">
                      <span>Sign in</span>
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 sign-right">
            <img src="assets/Group 2014.png" alt="" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Signup;
