import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Landing = () => {
  const [showItem, setShowItem] = useState(null);

  const handleShowItem = (index) => {
    setShowItem(showItem === index ? null : index);
  };

  return (
    <>
      <Navbar />

      {/* MAIN BANNER */}

      <div className="container-fluid main">
        <div className="container main-banner">
          <div className="main-content col-lg-6">
            <h1>
              Elevate Your{" "}
              <span>Amazon Business with Advanced Management Solutions</span>
            </h1>
            <p>
              We promise streamlined operations and enhanced performance. With
              powerful tools at your disposal, efficiently manage listings and
              optimize sales strategies. Gain a competitive edge and unlock your
              business's full potential on Amazon's platform.
            </p>
            <div className="main-button">
              <button>
                Learn More <i className="bi bi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* NEW ARRIVALS */}

      <div className="container new">
        <div className="new-header">
          <h3>New Arrivals</h3>
        </div>
        <div className="row new-row">
          <div className="col-lg-3">
            <div className="new-card">
              <div className="new-img">
                <img src="assets/image22.png" alt="" />
              </div>
              <div className="new-price">
                <div className="new-one">
                  <p>
                    $ 99.50 <span>$ 1128.00</span>
                  </p>
                  <div className="star">
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <span>4.9</span>
                  </div>
                </div>
                <div className="new-two">
                  <i className="bi bi-heart"></i>
                </div>
              </div>
              <p>GoPro HERO6 4K Action Camera - Black</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="new-card">
              <div className="new-img">
                <img src="assets/image35.png" alt="" />
              </div>
              <div className="new-price">
                <div className="new-one">
                  <p>
                    $ 99.50 <span>$ 1128.00</span>
                  </p>
                  <div className="star">
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <span>4.9</span>
                  </div>
                </div>
                <div className="new-two">
                  <i className="bi bi-heart"></i>
                </div>
              </div>
              <p>GoPro HERO6 4K Action Camera - Black</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="new-card">
              <div className="new-img">
                <img src="assets/7.png" alt="" />
              </div>
              <div className="new-price">
                <div className="new-one">
                  <p>
                    $ 99.50 <span>$ 1128.00</span>
                  </p>
                  <div className="star">
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <span>4.9</span>
                  </div>
                </div>
                <div className="new-two">
                  <i className="bi bi-heart"></i>
                </div>
              </div>
              <p>GoPro HERO6 4K Action Camera - Black</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="new-card">
              <div className="new-img">
                <img src="assets/image22.png" alt="" />
              </div>
              <div className="new-price">
                <div className="new-one">
                  <p>
                    $ 99.50 <span>$ 1128.00</span>
                  </p>
                  <div className="star">
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <span>4.9</span>
                  </div>
                </div>
                <div className="new-two">
                  <i className="bi bi-heart"></i>
                </div>
              </div>
              <p>GoPro HERO6 4K Action Camera - Black</p>
            </div>
          </div>
        </div>
      </div>

      {/* OUR PLANS */}

      <div className="container plan">
        <div className="plan-heading">
          <h1>Our Plans</h1>
          <p>
            Customized Solutions to Fit Your Amazon Business Needs: Tailored
            Pricing <br /> Plans for Optimal Success
          </p>
        </div>
        <div className="row plan-row">
          <div className="col-lg-4">
              <div className="plan-card">
                <h2>Free</h2>
                <h1>$ 0</h1>
                <h6>per user/month, billed annually</h6>
                <h5>For One Product</h5>
                <div className="plan-list">
                  <li>
                    <i className="bi bi-check-lg"></i>
                    List One Product
                  </li>
                  <li>
                    <i className="bi bi-check-lg"></i>
                    No Inventory Space 
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    Product Capacity
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    All 3 Plan Benefit
                  </li>
                </div>
                <div className="plan-button">
                  <button>Get Started</button>
                </div>
              </div>
          </div>
          <div className="col-lg-4">
              <div className="plan-card">
                <h2>Standard</h2>
                <h1>$ 39</h1>
                <h6>per user/month, billed annually</h6>
                <h5>For Growing Teams</h5>
                <div className="plan-list">
                  <li>
                    <i className="bi bi-check-lg"></i>
                    List Unlimited Product
                  </li>
                  <li>
                    <i className="bi bi-check-lg"></i>
                    One Inventory Space 
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    Product Capacity
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    All 3 Plan Benefit
                  </li>
                </div>
                <div className="plan-button">
                  <button>Get Started</button>
                </div>
              </div>
          </div>
          <div className="col-lg-4">
              <div className="plan-card plan-card-dark">
                <h2>Pro</h2>
                <h1>$ 59 <span>-15%</span></h1>
                <h6>per user/month, billed annually</h6>
                <h5>For Scaling Businesses</h5>
                <div className="plan-list plan-list-dark">
                  <li>
                    <i className="bi bi-check-lg"></i>
                    List Unlimited Products
                  </li>
                  <li>
                    <i className="bi bi-check-lg"></i>
                    4 Inventory Space 
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    Unlimited Product Capacity
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    All 3 Plan Benefit
                  </li>
                </div>
                <div className="plan-button plan-button-dark">
                  <button>Get Started</button>
                </div>
              </div>
          </div>
        </div>
      </div>

      {/* GOT QUESTIONS */}

      <div className="container ques">
        <div className="row ques-row">
          <div className="col-lg-6">
            <div className="ques-left">
              <h1>Got Questions? Just Write and Send Your Question</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ques-right">
              <form action="/" className="ques-form">
                <h2>Ask Your Questions</h2>
                <input type="text" placeholder="Email ID" />
                <textarea name="ques" id="ques" placeholder="Write Your Question"></textarea>
                <button className="btn btn-primary">Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ */}

      <div className="container faq">
        <div className="faq-heading">
          <h1>FAQs</h1>
          <div className="faq-cards">
            <div className="faq-card col-lg-8 mx-auto">
              <div className="faq-ch" onClick={() => handleShowItem(1)}>
                <h3>Can i manage my products accordingly ? </h3>
                <i className="bi bi-chevron-down"></i>
              </div>
              {showItem === 1 && (
                <div className="faq-p">
                  <p>
                    Our Hobby plan is for personal, non-commercial use. Pro is
                    for small teams with moderate bandwidth and collaboration
                    requirements. Enterprise is for with moderate bandwidth and
                    collaboration requirements. Enterprise is for{" "}
                  </p>
                </div>
              )}
            </div>
            <div className="faq-card col-lg-8 mx-auto">
              <div className="faq-ch" onClick={() => handleShowItem(2)}>
                <h3>Can i manage my products accordingly ? </h3>
                <i className="bi bi-chevron-down"></i>
              </div>
              {showItem === 2 && (
                <div className="faq-p">
                  <p>
                    Our Hobby plan is for personal, non-commercial use. Pro is
                    for small teams with moderate bandwidth and collaboration
                    requirements. Enterprise is for with moderate bandwidth and
                    collaboration requirements. Enterprise is for{" "}
                  </p>
                </div>
              )}
            </div>
            <div className="faq-card col-lg-8 mx-auto">
              <div className="faq-ch" onClick={() => handleShowItem(3)}>
                <h3>Can i manage my products accordingly ? </h3>
                <i className="bi bi-chevron-down"></i>
              </div>
              {showItem === 3 && (
                <div className="faq-p">
                  <p>
                    Our Hobby plan is for personal, non-commercial use. Pro is
                    for small teams with moderate bandwidth and collaboration
                    requirements. Enterprise is for with moderate bandwidth and
                    collaboration requirements. Enterprise is for{" "}
                  </p>
                </div>
              )}
            </div>
            <div className="faq-card col-lg-8 mx-auto">
              <div className="faq-ch" onClick={() => handleShowItem(4)}>
                <h3>Can i manage my products accordingly ? </h3>
                <i className="bi bi-chevron-down"></i>
              </div>
              {showItem === 4 && (
                <div className="faq-p">
                  <p>
                    Our Hobby plan is for personal, non-commercial use. Pro is
                    for small teams with moderate bandwidth and collaboration
                    requirements. Enterprise is for with moderate bandwidth and
                    collaboration requirements. Enterprise is for{" "}
                  </p>
                </div>
              )}
            </div>
            <div className="faq-card col-lg-8 mx-auto">
              <div className="faq-ch" onClick={() => handleShowItem(5)}>
                <h3>Can i manage my products accordingly ? </h3>
                <i className="bi bi-chevron-down"></i>
              </div>
              {showItem === 5 && (
                <div className="faq-p">
                  <p>
                    Our Hobby plan is for personal, non-commercial use. Pro is
                    for small teams with moderate bandwidth and collaboration
                    requirements. Enterprise is for with moderate bandwidth and
                    collaboration requirements. Enterprise is for{" "}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      

      <Footer />
    </>
  );
};

export default Landing;
