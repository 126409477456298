import React, { useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import $ from "jquery";

const Withdrawl = () => {
  const dataTableRef = useRef();

  useEffect(() => {
    $(dataTableRef.current).DataTable();
  }, []);

  return (
    <>
      <Sidebar />

      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Withdrawals</h1>
          </div>

          <div className="row dash-row">
            <div className="col-lg-4">
              <div className="dash-card p-3 d-flex justify-content-between align-items-center">
                <div className="dash">
                  <h3>Available Balance</h3>

                  <h2>$ 2700</h2>
                </div>
                <div>
                  <button className="btn btn-primary">Withdraw</button>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div class="input-group search-group">
              <input
                type="date"
                class="form-control search-control"
                placeholder="dd/mm/yyyy"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
              {/* <div class="input-group-prepend search-prepend">
                <span className="input-group-text search-text" id="basic-addon1">
                  <i className="bi bi-calendar"></i>
                </span>
              </div> */}
            </div>
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column">
                <div className="container mt-4">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        <th>
                          <input type="checkbox" />
                        </th>
                        <th>Withdrawal Id</th>
                        <th>Withdrawal Date</th>
                        <th>Withdrawal Amount</th>
                        <th>Withdrawal By</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>#09089</td>
                        <td>26-04-2024</td>
                        <td>$99.70</td>
                        <td>PayPal</td>
                        <td className="pending">
                          <i class="fa-solid fa-spinner"></i> Pending
                        </td>
                        <td>
                          <i className="fa-solid fa-trash"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>#09089</td>
                        <td>26-04-2024</td>
                        <td>$99.70</td>
                        <td>PayPal</td>
                        <td className="success">
                          <i class="fa-solid fa-check"></i> Success
                        </td>
                        <td>
                          <i className="fa-solid fa-trash"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>#09089</td>
                        <td>26-04-2024</td>
                        <td>$99.70</td>
                        <td>PayPal</td>
                        <td className="success">
                          <i class="fa-solid fa-check"></i> Success
                        </td>
                        <td>
                          <i className="fa-solid fa-trash"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>#09089</td>
                        <td>26-04-2024</td>
                        <td>$99.70</td>
                        <td>PayPal</td>
                        <td className="success">
                          <i class="fa-solid fa-check"></i> Success
                        </td>
                        <td>
                          <i className="fa-solid fa-trash"></i>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>#09089</td>
                        <td>26-04-2024</td>
                        <td>$99.70</td>
                        <td>PayPal</td>
                        <td className="success">
                          <i class="fa-solid fa-check"></i> Success
                        </td>
                        <td>
                          <i className="fa-solid fa-trash"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdrawl;
