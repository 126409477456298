import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Signin = () => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Navbar />

      <div className="container signin">
        <div className="row sign-row">
          <div className="col-lg-6">
            <div className="sign-left">
              <div className="sign-img">
                <img src="asssets/Logo.png" alt="" />
              </div>
              <h3>Welcome back !!!</h3>
              <h1>Sign in</h1>
              <form action="/" className="sign-form">
                <label htmlFor="email">Email</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    aria-label="email"
                    aria-describedby="basic-addon2"
                  />
                </div>
                <label htmlFor="password">Password</label>
                <div class="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    class="form-control"
                    placeholder="Password"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" onClick={togglePasswordVisibility}>
                      <i className="bi bi-eye"></i>
                    </span>
                  </div>
                </div>
                <div className="forgot">
                  <Link to="/forgot" className="text-decoration-none">
                    <h4>Forgot Password ?</h4>
                  </Link>
                </div>
                <div className="sign-button">
                  <Link to="/" className="text-decoration-none">
                    <button>
                      SIGN IN <i className="bi bi-arrow-right"></i>
                    </button>
                  </Link>
                </div>
                <div className="sign-acc">
                  <p>
                    Don't have an account ?{" "}
                    <Link to="/signup" className="text-decoration-none">
                      <span>Sign up</span>
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 sign-right">
            <img src="assets/Group 2014.png" alt="" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Signin;
