import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Forgot = () => {
  return (
    <>
      <Navbar />

      <div className="container signin">
        <div className="row sign-row">
          <div className="col-lg-6">
            <div className="sign-left">
              <div className="sign-img">
                <img src="assets/Logo.png" alt="" />
              </div>
              <h1>Forgot Password ?</h1>
              <form action="/" className="sign-form">
                <label htmlFor="email">Email Address</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    aria-label="email"
                    aria-describedby="basic-addon2"
                  />
                </div>

                <div className="sign-button">
                  <Link to="/reset">
                    <button>
                      CONTINUE <i className="bi bi-arrow-right"></i>
                    </button>
                  </Link>
                </div>
                <div className="sign-acc">
                  <p>
                    Remembered Password ?{" "}
                    <Link to="/signin" className="text-decoration-none">
                      <span>Sign in</span>
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 sign-right">
            <img src="assets/Group 2014.png" alt="" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Forgot;
