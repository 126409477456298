import './App.css';
import Landing from './pages/Landing';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Signin from './pages/Signin';
import Forgot from './pages/Forgot';
import Reset from './pages/Reset';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Product from './pages/Product';
import Withdrawl from './pages/Withdrawl';
import Membership from './pages/Membership';
import Settings from './pages/Settings';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/signin" element={<Signin />} />
          <Route exact path="/forgot" element={<Forgot />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/product" element={<Product />} />
          <Route exact path="/withdrawal" element={<Withdrawl />} />
          <Route exact path="/membership" element={<Membership />} />
          <Route exact path="/settings" element={<Settings />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
