import React from "react";
import Sidebar from "./Sidebar";

const Membership = () => {
  return (
    <>
      <Sidebar />

      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Membership</h1>
          </div>
          <div className="col-lg-5">
            <div className="member-card">
              <h1>Membership</h1>
              <p>
                This will show your membership plan, You can purchase other plan
                from here as well
              </p>
              <h4>Active Plan</h4>
              <h5>STandard Membership</h5>
              <button className="btn btn-primary">Choose a Membership</button>
            </div>
          </div>
          <div className="row plan-row member-row">
          <div className="col-lg-4">
              <div className="plan-card">
                <h2>Free</h2>
                <h1>$ 0</h1>
                <h6>per user/month, billed annually</h6>
                <h5>For One Product</h5>
                <div className="plan-list">
                  <li>
                    <i className="bi bi-check-lg"></i>
                    List One Product
                  </li>
                  <li>
                    <i className="bi bi-check-lg"></i>
                    No Inventory Space 
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    Product Capacity
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    All 3 Plan Benefit
                  </li>
                </div>
                <div className="plan-button">
                  <button>Get Started</button>
                </div>
              </div>
          </div>
          <div className="col-lg-4">
              <div className="plan-card">
                <h2>Standard</h2>
                <h1>$ 39</h1>
                <h6>per user/month, billed annually</h6>
                <h5>For Growing Teams</h5>
                <div className="plan-list">
                  <li>
                    <i className="bi bi-check-lg"></i>
                    List Unlimited Product
                  </li>
                  <li>
                    <i className="bi bi-check-lg"></i>
                    One Inventory Space 
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    Product Capacity
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    All 3 Plan Benefit
                  </li>
                </div>
                <div className="plan-button">
                  <button>Get Started</button>
                </div>
              </div>
          </div>
          <div className="col-lg-4">
              <div className="plan-card plan-card-dark">
                <h2>Pro</h2>
                <h1>$ 59 <span>-15%</span></h1>
                <h6>per user/month, billed annually</h6>
                <h5>For Scaling Businesses</h5>
                <div className="plan-list plan-list-dark">
                  <li>
                    <i className="bi bi-check-lg"></i>
                    List Unlimited Products
                  </li>
                  <li>
                    <i className="bi bi-check-lg"></i>
                    4 Inventory Space 
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    Unlimited Product Capacity
                  </li>
                  <li>
                    <i className="bi bi-x"></i>
                    All 3 Plan Benefit
                  </li>
                </div>
                <div className="plan-button plan-button-dark">
                  <button>Get Started</button>
                </div>
              </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Membership;
