import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Reset = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <Navbar />

      <div className="container signin">
        <div className="row sign-row">
          <div className="col-lg-6">
            <div className="sign-left">
              <div className="sign-img">
                <img src="assets/Logo.png" alt="" />
              </div>
              <h1>Reset Password</h1>
              <form action="/" className="sign-form">
                <label htmlFor="password">New Password</label>
                <div class="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    class="form-control"
                    placeholder="Enter Password"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" onClick={togglePasswordVisibility}>
                      <i className="bi bi-eye"></i>
                    </span>
                  </div>
                </div>
                <label htmlFor="password">Confirm Password</label>
                <div class="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    class="form-control"
                    placeholder="Confirm Password"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2" onClick={togglePasswordVisibility}>
                      <i className="bi bi-eye"></i>
                    </span>
                  </div>
                </div>

                <div className="sign-button">
                  <Link to="/signin">
                    <button>
                      CONTINUE <i className="bi bi-arrow-right"></i>
                    </button>
                  </Link>
                </div>
                <div className="sign-acc">
                  <p>
                    Remembered Password ?{" "}
                    <Link to="/signin" className="text-decoration-none">
                      <span>Sign in</span>
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 sign-right">
            <img src="assets/Group 2014.png" alt="" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Reset;
